/* TransitionWrapper.css */
.fade-enter {
    opacity: 0;
    /* Adjust as needed */
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1000ms, transform 1000ms;
    /* Match the duration with the timeout in TransitionWrapper */
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
    /* Match the duration with the timeout in TransitionWrapper */
}