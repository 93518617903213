@tailwind components;

body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

body[dir="ltr"] {
  direction: ltr;
  text-align: left;
}

nav {
  margin-bottom: 20px;
}

button {
  margin: 0 5px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #e6ebf5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Custom Styles for heading component */


@layer components {

  .bottom-line {
    position: relative;
  }

  .bottom-line::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-bottom: 2px solid rgb(238, 238, 238);
  }

  .bottom-line::after {
    content: "";
    display: block;
    width: 250px;
    border-bottom: 2px solid rgb(200, 184, 164);
    margin: 20px auto 25px 0px;
    z-index: 1;
    position: relative;
  }

  .bottom-line-ar {
    position: relative;
  }

  .bottom-line-ar::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-bottom: 2px solid rgb(238, 238, 238);
  }

  .bottom-line-ar::after {
    content: "";
    display: block;
    width: 250px;
    border-bottom: 2px solid rgb(200, 184, 164);
    margin: 20px 0px 25px auto;
    z-index: 1;
    position: relative;
  }
}

/* CardDifferent component  */
.card {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2s ease-out, transform 2s ease-out;
}

.card.animate {
  opacity: 1;
  transform: translateY(0);
}

.whitespace-pre-line {
  white-space: pre-line;
}





/* Home card */
.block {
  display: block;
}

.p-6 {
  padding: 1.5rem;
}

.bg-white {
  background-color: white;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.hover\:bg-orange-100:hover {
  background-color: #fed7aa;
}

.transition-all {
  transition-property: all;
}

.duration-700 {
  transition-duration: 1000ms;
}

.ease-in-out {
  transition-timing-function: ease-in-out;
}

.transform {
  transform: translateY(0);
}

.translate-y-20 {
  transform: translateY(20px);
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 100;
}

/* AboutUs animations */
@keyframes slide-in-right {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-slide-in-right {
  animation: slide-in-right 2s ease-in-out forwards;
}

.animate-fade-in {
  animation: fade-in 2s ease-in-out forwards;
}

/* Add this to your global CSS or a specific stylesheet */
@supports (-webkit-touch-callout: none) {
  .bg-fixed-ios {
    background-attachment: scroll;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .bg-fixed-ios {
      background-attachment: scroll;
    }
  }
}