@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Tajawal', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/index.css or appropriate CSS file */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s ease-out, transform 2s ease-out;
  /* Increased duration */
}

.fade-in-up-visible {
  opacity: 1;
  transform: translateY(0);
}